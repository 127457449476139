import React from "react"
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl"
import Seo from "../components/SEO"
import Header from "../components/Header"
import PageNav from "../components/PageNav"

const Contact = (props) => {

  const intl = useIntl()

  return (
    <div className="min-h-screen flex flex-col">
      <Seo title={intl.formatMessage({ id: "contact.title.seo" })} />
      <Header />

      <div className="sir-breadcrumbs">
        <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
        <span class="text-sir-black-25 mx-1">/</span>
        <FormattedMessage id="global.menu.contact" />
      </div>

      <div className="flex-grow">
        <div className="sir-section sir-layout-2-3 text-center mb-24">
          <h1 className="title-l mb-8 uppercase">
          <FormattedMessage id="contact.title" />
          </h1>
          <a href="mailto:sirjerunge@hotmail.com" className="body-l"><span className="link "><FormattedMessage id="contact.email" /></span> &rarr;</a>
        </div>
      </div>
      <PageNav />
    </div>
  )
}

export default Contact;
